import React from 'react';

import { deselectTriggerfilter } from './utils/deselectTriggerfilter';
import { MultipleSelectChoice } from './types/MultipleSelectChoice';
import Checkbox from '../../../_ui/_blocks/Form/Inputs/Checkbox/Checkbox';

interface props {
    name: string;
    value?: MultipleSelectChoice[];
    onChange: (value: any, isValid: boolean) => void;
    options: MultipleSelectChoice[];
    isValid: boolean;
    deselect_trigger?: MultipleSelectChoice[];
}

export const validation = (val: MultipleSelectChoice[]): boolean => !!val.length;

/**
 * Question type === multiple-selection
 *
 * This is a controllable component. The handleOnChange updates the prop "value" which will update
 * this component.
 */
const QTMultipleSelection = ({ name, value, onChange, options, isValid, deselect_trigger: deselectTrigger }: props): JSX.Element => {
    const choices = Array.isArray(value) ? value : [];

    const handleUserSelect = (selectedChoiceIsChecked: boolean, selectedChoice: any) => {
        let newChoices = [...choices];

        /** Add a choice to the array */
        if (selectedChoiceIsChecked) newChoices.push(selectedChoice);
        /** Remove choice from array */
        if (!selectedChoiceIsChecked) newChoices = newChoices.filter((item) => item !== selectedChoice);

        /** If a deselectTrigger is set and someone selects it, remove all other choices and select the deselect trigger only */
        /** If its already selected and someone selects something else, deselect it and only show the new option */
        newChoices = deselectTriggerfilter(deselectTrigger, newChoices, selectedChoice);

        onChange(newChoices, validation(newChoices));
    };

    if (!options || !options.length) return <p>No options provided, please contact support.</p>;

    return (
        <ul data-testid="multiple-selection" className="space-y-050">
            {options.map((option, i) => (
                <li key={`${name}_${i}`}>
                    <Checkbox
                        renderInOptionBlock
                        id={`${name}_${i}`}
                        name={name}
                        checked={choices.includes(option)}
                        onChange={(val) => handleUserSelect(val, option)}
                        isValid={isValid}
                        className="!font-main"
                    >
                        {
                            option.toString().replace('&#x2c;', ',') // We allow in the CP &#x2c; to be input in the option so that we can display a comma in the options
                        }
                    </Checkbox>
                </li>
            ))}
        </ul>
    );
};

QTMultipleSelection.defaultProps = {
    value: [],
    deselect_trigger: undefined,
};

export default QTMultipleSelection;
