import React, { useState, useCallback, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { useAuthContext } from '../../../../data/context/authContext';

import GPForm from '../../../GPForm/GPForm';
import { useDidUpdateEffect } from '../../../../hooks/useDidUpdateEffect';
import masterAnswersLookup from '../../../../helpers/masterAnswersLookup';
import PrefilledAnswer from '../../QuestionTypes/common/PrefilledAnswer/PrefilledAnswer';
import { GPSurgery } from '../../../GPForm/types/GPSurgery';
import Checkbox from '../../../_ui/_blocks/Form/Inputs/Checkbox/Checkbox';

import styles from './QTSpecialGP.module.css';

export const requiredErrorMessage =
    'We require you to consent to sharing data with your GP for us to be able to prescribe this treatment. Please provide your GP details once you have provided consent.';

interface QTSpecialGPValue {
    address: GPSurgery | null;
    gpConsent: boolean;
    scrConsent: boolean;
}

interface Props {
    value: QTSpecialGPValue | null;
    onChange: (value: Partial<QTSpecialGPValue> | null, valid: boolean) => void;
    isDirty: boolean;
    consent: {
        gp_contact: boolean;
        scr: boolean;
    };
}

/**
 * Special question type for GP.
 * NOTE: We need to manually control the dirty state because when someone interacts with this question its
 * essentially a 2 part. One checking the box and then entering the details, although checking the box we
 * dont want the dirty state to hit and show the error yet until interacting with the address form.
 *
 * @param Props - Props
 * @returns {JSX.Element}
 */
const QTSpecialGP = ({ value, onChange, isDirty, consent: consentQuestionRequirements }: Props): JSX.Element => {
    // eslint-disable-line object-curly-newline
    const { patient } = useAuthContext();

    // Cache the onChange function in a useCallback for use in the use effect.
    const cb = useCallback(onChange, []);

    // Add state and function for the GP consent checkbox.
    const [gpConsent, setGpConsent] = useState(!!get(value, 'gpConsent'));
    const handleGpConsentClick = () => setGpConsent((state) => !state);

    // Add state and function for the SCR consent checkbox.
    const [scrConsent, setScrConsent] = useState(!!get(value, 'scrConsent'));
    const handleScrConsentClick = () => setScrConsent((state) => !state);

    // Set the default GP details if available.
    const [surgeryDetails, setSurgeryDetails] = useState<Partial<GPSurgery>>(
        get(value, 'address') || get(masterAnswersLookup.getAnswer('surgery-address'), 'answer.address') || get(patient, 'gp.surgery') || {}
    );
    const updateSurgeryDetails = (surgDetails: Partial<GPSurgery>) => {
        setSurgeryDetails(surgDetails);
    };

    // If a prefill version of the address should be shown.
    const [showPrefill, setShowPrefill] = useState(Object.keys(surgeryDetails).length > 0);

    // Determins if the value is valid or not.
    const isValid = useMemo(() => {
        if (consentQuestionRequirements.scr === true && scrConsent === false) {
            return false;
        }

        if (consentQuestionRequirements.gp_contact && gpConsent === false) {
            return false;
        }

        if (gpConsent === true && (!surgeryDetails.name || !surgeryDetails.postcode)) {
            return false;
        }

        return true;
    }, [gpConsent, scrConsent, surgeryDetails]);

    // If the details have changed, update the answer.
    useDidUpdateEffect(() => {
        const checkName = surgeryDetails.name && surgeryDetails.name.trim();
        const checkPostcode = surgeryDetails.postcode && surgeryDetails.postcode.trim();

        let newAddress: Omit<QTSpecialGPValue, 'address'> | null = {
            gpConsent,
            scrConsent,
        };

        /** only add the address if name and postcode exists */
        if (checkName && checkPostcode) {
            newAddress = {
                ...newAddress,
                address: surgeryDetails as GPSurgery,
            } as QTSpecialGPValue;
        }

        return cb(newAddress, isValid);
    }, [cb, surgeryDetails, isValid, gpConsent, scrConsent]);

    const isScrCheckboxValid = isDirty ? !consentQuestionRequirements.scr || scrConsent : true;
    const isGpCheckboxValid = isDirty ? !consentQuestionRequirements.gp_contact || gpConsent : true;

    return (
        <div className="space-y-150">
            <div>
                <Checkbox
                    renderInOptionBlock
                    id="form-field--nhs-consent"
                    name="nhs_consent"
                    onChange={handleScrConsentClick}
                    checked={scrConsent}
                    isValid={isScrCheckboxValid}
                    className={styles.checkbox}
                >
                    Do you consent to us accessing your NHS Summary Care Record (the medical details held by your GP) or requesting further
                    information on your medical history from your GP?
                </Checkbox>
            </div>
            <div>
                <Checkbox
                    renderInOptionBlock
                    id="form-field--gp-consent"
                    name="gp_consent"
                    onChange={handleGpConsentClick}
                    checked={gpConsent}
                    isValid={isGpCheckboxValid}
                    className={styles.checkbox}
                >
                    Do you consent to us sharing consultation information with your GP?
                </Checkbox>
            </div>
            {gpConsent ? (
                <Fragment>
                    {showPrefill ? (
                        <PrefilledAnswer
                            questionType="s-gp"
                            // TODO: fix types
                            // @ts-ignore
                            answer={surgeryDetails}
                            changeAnswer={() => setShowPrefill(false)}
                            options={[]}
                        />
                    ) : (
                        <GPForm
                            defaultManuallyEnter={!!surgeryDetails.name}
                            isDirty={isDirty}
                            // TODO: fix types
                            // @ts-ignore
                            onSurgeryDetailsChange={updateSurgeryDetails}
                            defaultSurgeryDetails={surgeryDetails}
                        />
                    )}
                </Fragment>
            ) : null}
        </div>
    );
};

QTSpecialGP.defaultProps = {
    value: null,
    isDirty: false,
};

QTSpecialGP.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            name: PropTypes.string,
            address1: PropTypes.string,
            address2: PropTypes.string,
            address3: PropTypes.string,
            postcode: PropTypes.string,
        }),
    ]),
    isDirty: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

export default QTSpecialGP;
