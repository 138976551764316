import React from 'react';

import YesNo from '../common/YesNo/YesNo';

export const validation = (val: 1 | 0) => val === 1 || val === 0;

interface Props {
    name: string;
    value: number;
    onChange: (val: number, valid: boolean) => void;
    isValid: boolean;
    isDirty: boolean;
}
/**
 * Question type === yes-no
 *
 * This is a controllable component meaning the radios will not be checked unless a higher state
 * will get passed down with the value that matches the no state.
 */
const QTYesNo = ({ name, value, onChange, isValid, isDirty }: Props) => {
    const handleOnChange = (val: 1 | 0) => {
        onChange(val, validation(val));
    };

    return (
        <div data-testid="yes-no">
            <YesNo value={value} name={name} onChange={handleOnChange} isValid={isValid} isDirty={isDirty} />
        </div>
    );
};

export default QTYesNo;
