import React, { useCallback } from 'react';
import FieldWrapper from '../../../../_ui/_blocks/Form/FieldWrapper/FieldWrapper';
import NumberInput from '../../../../_ui/_blocks/Form/Inputs/NumberInput/NumberInput';

interface Props {
    height: number | string;
    onChange: (height: number) => void;
    isValid: boolean;
}

const Centimeters = ({ height = '', onChange, isValid }: Props) => {
    const cb = useCallback(onChange, []);

    return (
        <FieldWrapper id="form-field--height-cm">
            <NumberInput
                id="form-field--height-cm"
                label="Centimeters (cm)"
                name="height"
                type="number"
                placeholder="e.g. 165"
                // @ts-expect-error
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => cb(Math.round(e.target.value))}
                value={height}
                isValid={isValid}
                min={0}
                max={300}
                className="w-full max-w-[7.75rem]"
            />
        </FieldWrapper>
    );
};

export default Centimeters;
