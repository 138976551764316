import React, { ReactNode } from 'react';

import styles from './FieldWrapper.module.css';

interface Props {
    id: string;
    optional?: boolean;
    disabled?: boolean;
    label?: ReactNode | undefined;
    subLabel?: ReactNode | undefined;
    children: ReactNode;
    className?: string;
}

const FieldWrapper = ({
    id,
    children,
    optional = false,
    disabled = false,
    label = undefined,
    subLabel = undefined,
    className = '',
}: Props) => (
    <div className={`${styles.form_field__wrapper} ${disabled ? `${styles.form_field__wrapper__disabled}` : ''}`} data-testid={id}>
        <div>
            {label ? (
                <label htmlFor={id} className={`type-heading type-090 ${className}`}>
                    <span>{label}</span>
                    {optional ? <span className="type-meta type-090 text-content-quiet"> (Optional)</span> : null}
                </label>
            ) : null}
            {subLabel ? <p className="text-color--slate">{subLabel}</p> : null}
        </div>
        {children}
    </div>
);

export default FieldWrapper;
