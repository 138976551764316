import React from 'react';
import PropTypes from 'prop-types';
import trimstart from 'lodash.trimstart';

import Textarea from '@/components/_ui/_blocks/Form/Inputs/Textarea/Textarea';

export const validation = (val) => !!val;

/**
 * Question type === free-text
 *
 * This is a controllable component meaning the value in the question will not be set unless a
 * higher state will get passed down.
 */
const QTFreeText = ({ name, value, isValid, isDirty, onChange }) => {
    const handleOnChange = (e) => {
        const val = trimstart(e.target.value);
        onChange(val, validation(val));
    };

    return (
        <Textarea
            className="w-full"
            rows={4}
            data-testid="free-text"
            isValid={isValid}
            isDirty={isDirty}
            name={name}
            onChange={handleOnChange}
            placeholder="Please provide details"
            value={value}
        />
    );
};

QTFreeText.defaultProps = {
    value: '',
};

QTFreeText.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
};

export default QTFreeText;
