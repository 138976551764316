import { cmToFtInch } from '../../../../../helpers/maths';

const getHeightError = (height: number, heightConversion = 'cm') => {
    if (!height && height !== 0) return '';
    if (height <= 55) return `Your height should be greater than ${heightConversion === 'cm' ? '55cm' : cmToFtInch(55, true)}`;
    if (height >= 272) return `Your height should be less than ${heightConversion === 'cm' ? '272cm' : cmToFtInch(272, true)}`;
    return '';
};

export default getHeightError;
