import React from 'react';
import YesNo from '../common/YesNo/YesNo';

export const validation = (val: 1 | 0) => val === 0;

interface Props {
    name: string;
    value: number;
    onChange: (val: number, valid: boolean) => void;
    isValid: boolean;
    isDirty: boolean;
}
/**
 * Question type === no
 *
 * This is a controllable component meaning the radios will not be checked unless a higher state
 * will get passed down with the value that matches the no state.
 */
const QTNo = ({ name, value, onChange, isValid, isDirty }: Props) => {
    const handleOnChange = (val: 1 | 0) => onChange(val, validation(val));

    return (
        <div data-testid="no">
            <YesNo name={name} value={value} onChange={handleOnChange} isValid={isValid} isDirty={isDirty} />
        </div>
    );
};

export default QTNo;
