/* eslint-disable no-restricted-properties */
import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { calculateBMI, kgToGrams, gramsToKG } from '../../../../helpers/maths';

import { useDidUpdateEffect } from '../../../../hooks/useDidUpdateEffect';
import { useAuthContext } from '../../../../data/context/authContext';
import getHeightError from './helpers/getHeightError';
import getWeightError from './helpers/getWeightError';

import Error from '../../../_ui/_blocks/Form/Validation/Error/Error';
import Centimeters from './Height/Centimeters';
import FtAndIn from './Height/FtAndIn';
import Kilograms from './Weight/Kilograms';
import StAndLb from './Weight/StAndLb';
import Typography from '../../../_ui/_blocks/Typography/Typography';
import Hr from '../../../_ui/_blocks/Hr/Hr';

export const validation = (val, required) => {
    if (required && !val) return false;

    const weight = get(val, 'weight') || 0;
    const height = get(val, 'height') || 0;

    const heightError = getHeightError(height);
    const weightError = getWeightError(kgToGrams(weight) || 0);

    return !heightError && !weightError;
};

/**
 * BMI question type
 */
const QTSpecialBMI = ({ value, onChange, isDirty, required }) => {
    const cb = useCallback(onChange, []);

    const { patient } = useAuthContext();
    const pateintHeight = patient ? patient.personal.height : null;

    const [height, setHeight] = useState(value && value.weight ? parseFloat(value.height) : pateintHeight || ''); // Always set in cm
    const [weight, setWeight] = useState(kgToGrams(value && value.weight ? parseFloat(value.weight) : '')); // Always set in kg
    const [weightConversion, setWeightConversion] = useState('kg'); // Initial field will be Kilograms.
    const [heightConversion, setHeightConversion] = useState('ft'); // Initial fields will be Feet and Intches.

    const toggleHeightConversion = () => setHeightConversion((s) => (s === 'cm' ? 'ft' : 'cm'));
    const toggleWeightConversion = () => setWeightConversion((s) => (s === 'kg' ? 'st' : 'kg'));

    /**
     * Check height and weight are valid functions.
     */
    const heightError = useMemo(() => getHeightError(height, heightConversion), [height, heightConversion]);
    const weightError = useMemo(() => getWeightError(weight, weightConversion), [weight, weightConversion]);

    // Handles height and wight changes.
    const handleHeightChange = (v) => setHeight(v);
    const handleWeightChange = (v) => setWeight(v ? parseFloat(v) : '');

    /**
     * On change update height and weight values using onChange callback.
     */
    useDidUpdateEffect(() => {
        const val =
            height && weight
                ? { height: Math.round(height), weight: Math.round(gramsToKG(weight)), bmi: calculateBMI(height, gramsToKG(weight)) }
                : undefined;

        const valid = validation(val, required);

        // We only want to send the state higher up if it's already been sent once, or if its got a value.
        if (isDirty || val) cb(val, valid, true, heightError || weightError);
    }, [cb, height, weight, heightError, weightError]);

    return (
        <div data-testid="s-bmi" className="space-y-200">
            <div className="flex justify-between items-center">
                <Typography as="h6" typeset="heading" size="100" lineHeight="200">Height</Typography>
                <button
                    className="type-090 type-link"
                    type="button"
                    onClick={toggleHeightConversion}
                >
                    Switch to {heightConversion === 'cm' ? 'Feet (ft)' : 'Centimeters (cm)'}
                </button>
            </div>
            <div>
                {heightConversion === 'cm' ? (
                    <Centimeters height={height} isValid={!heightError} onChange={handleHeightChange} />
                ) : (
                    <FtAndIn height={height} isValid={!heightError} onChange={handleHeightChange} />
                )}
            </div>
            <Hr />
            <div className="flex justify-between items-center">
                <Typography as="h6" typeset="heading" size="100" lineHeight="200">Weight</Typography>
                <button
                    className="type-090 type-link"
                    type="button"
                    onClick={toggleWeightConversion}
                >
                    Switch to {weightConversion === 'kg' ? 'Stone (st)' : 'Kilograms (kg)'}
                </button>
            </div>
            <div>
                {weightConversion === 'kg' ? (
                    <Kilograms weight={weight} isValid={!weightError} onChange={handleWeightChange} />
                ) : (
                    <StAndLb weight={weight} isValid={!weightError} onChange={handleWeightChange} />
                )}
            </div>
            <div>
                {heightError ? <Error>{heightError}</Error> : null}
                {weightError ? <Error>{weightError}</Error> : null}
            </div>
        </div>
    );
};

QTSpecialBMI.defaultProps = {
    value: undefined,
    onChange: null,
    required: 0,
    isDirty: false,
};

QTSpecialBMI.propTypes = {
    value: PropTypes.shape({
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    onChange: PropTypes.func,
    required: PropTypes.number,
    isDirty: PropTypes.bool,
};

export default QTSpecialBMI;
