import React, { useState } from 'react';
import GPSearch from './GPSearch';
import GPFormFields from './GPFormFields';
import { useDidUpdateEffect } from '../../hooks/useDidUpdateEffect';
import { GPSurgery } from './types/GPSurgery';
import PrefilledAddress from '../Addresses/AddressForm/PrefilledAddress/PrefilledAddress';
import Button from '../_ui/_blocks/Buttons/Button/Button';
import Typography from '../_ui/_blocks/Typography/Typography';

export enum GPFormViewState {
    SEARCH,
    EDIT,
    SELECTED_SURGERY,
}

interface Props {
    defaultManuallyEnter: boolean;
    defaultSurgeryDetails?: Partial<GPSurgery> | null;
    isDirty: boolean;
    onSurgeryDetailsChange: (surgeryDetails: React.SetStateAction<Partial<GPSurgery>>) => void;
}

/**
 * This component is used to display the GP form.
 * It is used to display the search form, the edit form, and the selected surgery response from algolias
 * @param param0
 * @returns
 */
const GPForm = ({ defaultManuallyEnter, isDirty, onSurgeryDetailsChange, defaultSurgeryDetails = null }: Props): JSX.Element => {
    const [viewState, setViewState] = useState<GPFormViewState>(defaultManuallyEnter ? GPFormViewState.EDIT : GPFormViewState.SEARCH);
    const [surgeryDetails, setSurgeryDetails] = useState<Partial<GPSurgery>>(defaultSurgeryDetails || {});

    const manualEditChange = (name: string, val: unknown) => {
        setSurgeryDetails((surgery) => {
            const surgeryCopy = { ...surgery };

            delete surgeryCopy.email;
            delete surgeryCopy.NHSGPPracticeCode;

            return {
                ...surgeryCopy,
                manualEdit: true,
                [name]: val,
            };
        });
    };

    useDidUpdateEffect(() => {
        onSurgeryDetailsChange(surgeryDetails);
    }, [surgeryDetails]);
    return (
        <div className="space-y-050">
            <GPSearch
                setSurgeryDetails={setSurgeryDetails}
                setViewState={setViewState}
                manuallyEnter={() => setViewState(GPFormViewState.EDIT)}
            />
            {viewState === GPFormViewState.SEARCH ? (
                <Button
                    className="p-0 type-090 type-link font-main"
                    variant="none"
                    type="button"
                    onClick={() => setViewState(GPFormViewState.EDIT)}
                    title="Enter you GP's address manually"
                >
                    Enter manually
                </Button>
            ) : null}
            {viewState === GPFormViewState.EDIT ? (
                <GPFormFields address={surgeryDetails} isDirty={isDirty} onChange={manualEditChange} />
            ) : null}

            {viewState === GPFormViewState.SELECTED_SURGERY ? (
                <div className="py-025">
                    <PrefilledAddress
                        address={surgeryDetails}
                        format={['name', 'address1', 'address2', 'city', 'county', 'postcode']}
                        buttonText="Edit"
                        oneLine
                        onClick={() => setViewState(GPFormViewState.EDIT)}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default GPForm;
