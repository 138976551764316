import React, { Fragment } from 'react';
import { Switch } from '@headlessui/react';
import clx from 'classnames';

import CheckboxIcon from './CheckboxIcon/CheckboxIcon';
import OptionBlock from '../OptionBlock/OptionBlock';

import styles from './Checkbox.module.css';

export type test = React.ComponentProps<typeof Switch>;

interface Props extends Omit<React.ComponentProps<typeof Switch>, 'as'> {
    disabled?: boolean;
    children: React.ReactNode;
    renderInOptionBlock?: boolean;
    isValid?: boolean;
    isDirty?: boolean;
}

/**
 * Checkbox component using headlessUIs switch component.
 * @see https://headlessui.com/react/switch
 */
const Checkbox = ({ children, className, renderInOptionBlock, isValid, isDirty, ...rest }: Props) => {
    const remainingProps = { ...rest };
    const { disabled } = remainingProps;

    const checkboxClassName = clx(styles.checkbox, className, {
        [styles.checkboxDisabled]: disabled,
    });

    const Wrapper = renderInOptionBlock ? OptionBlock : Fragment;

    return (
        <Switch {...remainingProps} className={checkboxClassName} as="label">
            {({ checked }) => (
                <Fragment>
                    <Wrapper checked={checked} isValid={isValid} isDirty={isDirty} disabled={disabled} className="!flex w-full gap-100">
                        <CheckboxIcon className="flex-shrink-0" checked={checked} disabled={disabled} />
                        {children}
                    </Wrapper>
                </Fragment>
            )}
        </Switch>
    );
};

export default Checkbox;
