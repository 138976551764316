/* eslint-disable camelcase */
import React from 'react';

import Checkbox from '@/components/_ui/_blocks/Form/Inputs/Checkbox/Checkbox';
import { get } from 'lodash';
import { stripHtml } from '../../../../helpers/text/textFormatting';

export const validation = (val: 1 | 0, required: boolean) => (required ? val === 1 : true);

interface Props {
    question_id: string | number;
    question_text: string;
    name: string;
    options: Array<string | number>;
    value: number | boolean;
    onChange: (value: number | boolean, isValid: boolean) => void;
    isValid: boolean;
    required: number;
}
/**
 * Checkbox type
 * This is valid only if its checked currently.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const QTCheckbox = ({ question_id, question_text, name, options, value = 0, onChange, isValid, required, ...rest }: Props) => {
    // @ts-expect-error
    const handleOnChange = (checked: boolean) => onChange(checked === true ? 1 : 0, validation(checked ? 1 : 0, required));

    // if (!options || !options.length) return <p>No options provided, please contact support.</p>;

    const option = get(options, '[0]', stripHtml(question_text)).replace('&#x2c;', ','); //  // We allow in the CP &#x2c; to be input in the option so that we can display a comma in the options;

    return (
        <div data-testid="checkbox">
            <Checkbox
                renderInOptionBlock
                id={`form-field--${question_id}`}
                name={name}
                checked={value === 1}
                onChange={(val) => handleOnChange(val)}
                isValid={isValid}
            >
                {option}
            </Checkbox>
        </div>
    );
};
export default QTCheckbox;
