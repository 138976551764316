import React from 'react';

import NumberInput from '@/components/_ui/_blocks/Form/Inputs/NumberInput/NumberInput';
import Error from '@/components/_ui/_blocks/Form/Validation/Error/Error';

export const validation = (val: number) => {
    if (Number.isNaN(val)) {
        return false;
    }

    if (val < 0) {
        return false;
    }

    return true;
};

interface Props {
    name: string;
    value: number;
    onChange: (val: number, isValid: boolean) => void;
    isValid: boolean;
    isDirty: boolean;
}

/**
 * Question type === number
 *
 * This is a controllable component meaning the value in the question will not be set unless a
 * higher state will get passed down.
 */
const QTNumber = ({ name, value = 0, onChange, isValid, isDirty }: Props) => {
    const handleOnChange = (val: number) => {
        onChange(val, validation(val));
    };

    const errorMessage = isDirty && validation(value) === false ? 'Please enter a number greater than or equal to 0.' : '';

    return (
        <div data-testid="number" className="space-y-050">
            <NumberInput
                id={name}
                name={name}
                className="w-full"
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(parseInt(e.target.value, 10))}
                value={!Number.isNaN(value) ? value : ''}
                isValid={isValid}
                min={0}
            />
            {errorMessage ? <Error>{errorMessage}</Error> : null}
        </div>
    );
};

export default QTNumber;
