import axios from '@/lib/axios/axios';

// import { MSW_URL } from '../../config/config';

export default {
    /**
     * When creating an asset the client provides a type to indicate the type of asset being uploaded.
     * Available types include: PATIENT_IMAGE - jpeg, jpg, gif, png - 4MB limit
     * @param {object} postData - Contains "type" and "file" keys.
     */
    createAsset: async (postData) => axios.post('/assets', postData, { headers: { 'Content-Type': 'multipart/form-data' } }),
};
