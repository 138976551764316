import React from 'react';
import { RadioGroup } from '@headlessui/react';
import RadioOption from './RadioOption/RadioOption';

export type test = React.ComponentProps<typeof RadioGroup>;

interface Props extends Omit<React.ComponentProps<typeof RadioGroup>, 'as'> {
    disabled?: boolean;
    children: React.ReactNode;
}

/**
 * Radio component using headlessUIs radioGroup component.
 * @see https://headlessui.com/react/radio-group
 */
const Radio = ({ children, className, ...remainingProps }: Props) => (
    <RadioGroup {...remainingProps} className={className}>
        {children}
    </RadioGroup>
);

Radio.Option = RadioOption;

export default Radio;
