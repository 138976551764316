import * as QTYesNo from './QuestionTypes/QTYesNo/QTYesNo';
import * as QTYes from './QuestionTypes/QTYes/QTYes';
import * as QTNo from './QuestionTypes/QTNo/QTNo';
import * as QTSelect from './QuestionTypes/QTSelect/QTSelect';
import * as QTGender from './QuestionTypes/QTGender/QTGender';
import * as QTFreeText from './QuestionTypes/QTFreeText/QTFreeText';
import * as QTMultipleSelection from './QuestionTypes/QTMultipleSelection/QTMultipleSelection';
import * as QTRadio from './QuestionTypes/QTRadio/QTRadio';
import * as QTNumber from './QuestionTypes/QTNumber/QTNumber';
import * as QTCheckbox from './QuestionTypes/QTCheckbox/QTCheckbox';
import * as QTImage from './QuestionTypes/QTImage/QTImage';
import * as QTDate from './QuestionTypes/QTDate/QTDate';
import * as QTSpecialGP from './SpecialQuestionTypes/QTGP/QTSpecialGP';
import * as QTSpecialBMI from './SpecialQuestionTypes/QTSpecialBMI/QTSpecialBMI';

const componentIndex = {
    checkbox: QTCheckbox,
    date: QTDate,
    'free-text': QTFreeText,
    gender: QTGender,
    image: QTImage,
    'multiple-selection': QTMultipleSelection, // dont change this type without changing in the question card.
    no: QTNo,
    number: QTNumber,
    radio: QTRadio, // dont change this type without changing in the question card.
    's-gp': QTSpecialGP,
    's-bmi': QTSpecialBMI,
    select: QTSelect,
    yes: QTYes,
    'yes-no': QTYesNo,
};

export default componentIndex;

export const questionTypes = [
    'checkbox',
    'date',
    'gender',
    'image',
    'no',
    'number',
    'radio',
    'select',
    'yes',
    'free-text',
    'multiple-selection',
    's-gp',
    's-bmi',
    'yes-no',
] as const;

export type QuestionTypes = (typeof questionTypes)[number];
