import React, { useState } from 'react';

import { capitalize } from '../../helpers/text/textFormatting';

import Icon from '../_ui/_blocks/Icon/Icon';
import FieldWrapper from '../_ui/_blocks/Form/FieldWrapper/FieldWrapper';
import useAlgoliaSearch from '../../hooks/useAlgoliaSearch';
import { GPSurgery } from './types/GPSurgery';
import { AlgoliaGPHit } from '../../types/algolia/AlgoliaGPHit';
import { GPFormViewState } from './GPForm';

import Button from '../_ui/_blocks/Buttons/Button/Button';
import Typography from '../_ui/_blocks/Typography/Typography';
import TextInput from '../_ui/_blocks/Form/Inputs/TextInput/TextInput';
import EnterManuallyButton from '../Addresses/AddressForm/EnterManuallyButton';
import LoadingIcon from '../_ui/_blocks/Icons/LoadingIcon/LoadingIcon';

import styles from './GPSearch.module.css';

const indexPrefix = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX;

interface Props {
    setSurgeryDetails: (surgeryResult: GPSurgery) => void;
    setViewState: (bool: GPFormViewState) => void;
    manuallyEnter: () => void;
}

const GPSearch = ({ setSurgeryDetails, setViewState, manuallyEnter }: Props): JSX.Element => {
    const [searchTerm, setSearchTerm] = useState('');

    const selectSurgery = (algoliaSurgeryHit: AlgoliaGPHit) => {
        setSurgeryDetails({
            name: capitalize(algoliaSurgeryHit.Org, true),
            address1: capitalize(algoliaSurgeryHit.Add1, true),
            address2:
                capitalize(algoliaSurgeryHit.Add2, true) ||
                capitalize(algoliaSurgeryHit.Add3, true) ||
                capitalize(algoliaSurgeryHit.Add4, true),
            city: capitalize(algoliaSurgeryHit.Town, true),
            county: capitalize(algoliaSurgeryHit.County, true),
            postcode: algoliaSurgeryHit.Postcode,
            email: algoliaSurgeryHit.MainEmail || '',
            NHSGPPracticeCode: algoliaSurgeryHit.NHSGPPracticeCode,
            manualEdit: false,
        });
    };

    /**
     * Invoke our algolia search hook to listen to our search terms.
     */
    const [{ isLoading, algoliaResponse }] = useAlgoliaSearch(
        searchTerm,
        `${indexPrefix}_GPs`,
        {
            enableAnalytics: false,
        },
        {
            hitsPerPage: 100,
        }
    );
    const searchResults = (algoliaResponse && algoliaResponse.hits) || false;

    return (
        <FieldWrapper id="form-field--gp-search" label="Search for your GP" className="type-090 font-800">
            <div className="relative">
                <div className="relative">
                    <TextInput
                        id="form-field--gp-search"
                        type="text"
                        name="gp_search"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                        value={searchTerm}
                        placeholder="Postcode"
                        className={styles.formField}
                        dataLpignore
                    />
                    {!isLoading ? (
                        <Icon icon="search-light" alt="search icon" className="absolute right-075 top-100" size="small" />
                    ) : (
                        <div className="absolute right-075 top-075">
                            <LoadingIcon />
                        </div>
                    )}
                </div>
                {searchTerm && searchResults ? (
                    <ul className={`${styles.formSearchResultsList} ${searchResults.length ? styles.formSearchResultsListRounded : ''}`}>
                        {searchResults.length ? (
                            searchResults.map((hit: AlgoliaGPHit) => {
                                const surgeryName = capitalize(hit.Org, true);
                                return (
                                    <li key={hit.objectID} className="overflow-hidden">
                                        <Button
                                            data-testid="gp-surgery-result"
                                            className={styles.formSearchResult}
                                            type="button"
                                            variant="none"
                                            onClick={() => {
                                                setSearchTerm('');
                                                selectSurgery(hit);
                                                setViewState(GPFormViewState.SELECTED_SURGERY);
                                            }}
                                            title={`Select ${surgeryName}`}
                                        >
                                            {surgeryName}, {hit.Postcode}
                                        </Button>
                                    </li>
                                );
                            })
                        ) : (
                            <li className={styles.formSearchNoResults}>
                                <span className="block">
                                    Sorry, no surgery results could be found using this name or address.
                                </span>
                                <EnterManuallyButton onClick={manuallyEnter} />
                            </li>
                        )}
                    </ul>
                ) : null}
            </div>
        </FieldWrapper>
    );
};

export default GPSearch;
