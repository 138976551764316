import React from 'react';

import { gramsToKG, kgToGrams } from '../../../../../helpers/maths';

import FieldWrapper from '../../../../_ui/_blocks/Form/FieldWrapper/FieldWrapper';
import NumberInput from '../../../../_ui/_blocks/Form/Inputs/NumberInput/NumberInput';

interface Props {
    weight: number | string;
    onChange: (weight: number) => void;
    isValid: boolean;
}

const Kilograms = ({ weight, isValid, onChange }: Props) => (
    <FieldWrapper id="form-field--weight-kg">
        <NumberInput
            id="kilogram_weight"
            label="Kilograms (kg)"
            name="weight"
            type="number"
            placeholder="e.g. 68.5"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(kgToGrams(e.target.value) as unknown as number)}
            value={gramsToKG(weight)}
            isValid={isValid}
            min={0}
            max={500}
            step="any"
            className="w-full max-w-[7.75rem]"
        />
    </FieldWrapper>
);

export default Kilograms;
