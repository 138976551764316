import { kgToStonePounds } from '../../../../../helpers/maths';

const getWeightError = (weight: number, weightConversion = 'kg') => {
    if (!weight && weight !== 0) return '';
    if (weight <= 2130) return `Your weight should be above  ${weightConversion === 'kg' ? '2.13kg' : kgToStonePounds(2.13, true)}`;
    if (weight >= 442000) return `Your weight should be less than  ${weightConversion === 'kg' ? '442kg' : kgToStonePounds(442, true)}`;
    return '';
};

export default getWeightError;
