import dayjs from '@/lib/dayjs/dayjs';

import { isBrowser } from '../../config/config';

/**
 * Checks if a date has a valid format and the date exists.
 * @param {string} date - The date to check.
 * @param {string} format - The format of the date to be checked.
 * @returns {boolean}
 */
export const isDateValid = (date: string, format = 'YYYY-MM-DD') => {
    const newDayJs = dayjs(date, format);

    // Checks the format is correct.
    if (newDayJs.isValid() === false) {
        return false;
    }

    // Checks the date actually exists e.g. 2021-02-31 doesn't exist.
    return newDayJs.format(format) === date;
};

/**
 * Calculate someones age from their date of birth.
 * @param {string} dob - The date of birth.
 * @param {string} format - Format the date of birth is in.
 * @returns {number|boolean} - Number of the age or false if invalid date is given.
 */
export const calculateAge = (dob: string, format = 'YYYY-MM-DD HH:mm:ss') => {
    // Checks the date is valid.
    if (isDateValid(dob, format) === false) {
        return false;
    }

    // Format the date to local time.
    const dayjsDob = dayjs.utc(dob).local();

    // Return the difference between now and then in years.
    return dayjs().local().diff(dayjsDob, 'year');
};

/**
 * Check if the given date has passed.
 * @param {string} date - The date to check.
 * @returns {boolean}
 */
export const hasDatePassed = (date: string, format = 'YYYY-MM-DD HH:mm:ss') => {
    const newDate = dayjs(date, format).local();
    return dayjs().local().isAfter(newDate);
};

/**
 * Checks if the given date is tomorrows date.
 * @param {string} date - The date to check.
 * @param {string} format - The format of the date to check.
 * @returns {boolean}
 */
export const isTomorrow = (date: string, format = 'YYYY-MM-DD') => {
    // Get tomorrows date.
    const tomorrow = dayjs().local().add(1, 'day').format(format);

    // If the date given matches tomorrows date.
    return tomorrow === date;
};

/**
 * Checks if the date provided is between 130 years ago and 100 years in the future.
 * @param {string} date - The date to check in the format of YYYY-MM-DD.
 * @param {number} startYear - Max number of years in the past.
 * @param {number} endYear - Max number of years in the future.
 * @returns {string}
 */
export const isDateBetweenYears = (date: string, yearsPast = 130, yearsFuture = 100) => {
    // Format the date.
    const dateProvided = dayjs(date, 'YYYY-MM-DD');

    // Get the min and max dates the date can be between.
    const maxFutureDate = dayjs().add(yearsFuture, 'year').format('YYYY-MM-DD');
    const minPastDate = dayjs().subtract(yearsPast, 'year').format('YYYY-MM-DD');

    // If the date is before the minimum year.
    if (dateProvided.isBefore(minPastDate) === true) {
        return 'MIN_ERROR';
    }

    // If the date is after the max year.
    if (dateProvided.isAfter(maxFutureDate) === true) {
        return 'MAX_ERROR';
    }

    return 'SUCCESS';
};

/**
 * Checks if a date is greater than another date.
 * @param {string} baseDate - The "min date" to use.
 * @param {string} dateToCheck - Date to check if it has passed the baseDate or "min date".
 * @param {string} format - The dayjs date format to use.
 * @returns boolean
 */
export const isDateGreaterThan = (baseDate: string, dateToCheck: string, format = 'YYYY-MM-DD') => {
    const newBaseDate = dayjs(baseDate, format).local();
    const newDateToCheck = dayjs(dateToCheck, format).local();

    // if the date is before the min date, return false, otherwise, return true.
    return newDateToCheck.isBefore(newBaseDate, 'day') ? false : true; // eslint-disable-line
};

/**
 * Checks if a date is less than another date.
 * @param {string} baseDate - The "max date" to use.
 * @param {string} dateToCheck - Date to check if it has passed the baseDate or "min date".
 * @param {string} format - The dayjs date format to use.
 * @returns boolean
 */
export const isDateLessThan = (baseDate: string, dateToCheck: string, format = 'YYYY-MM-DD') => {
    const newBaseDate = dayjs(baseDate, format).local();
    const newDateToCheck = dayjs(dateToCheck, format).local();

    // If the date is after the max date, return false, otherwise, return true.
    return newDateToCheck.isAfter(newBaseDate, 'day') ? false : true; // eslint-disable-line
};

/**
 * Checks if the date input has chrome formatting or is safari with no formatting.
 * @returns {boolean}
 */
export const checkDateSupport = () => {
    // If the browser is not available.
    if (!isBrowser()) {
        return false;
    }

    // Create an input element.
    const input = document.createElement('input');
    const inputValue = 'a';

    // Add the date attribute and value to the date input.
    input.setAttribute('type', 'date');
    input.setAttribute('value', inputValue);

    // If the value is not the same as the value passed in, the input has date support.
    return input.value !== inputValue;
};
