import React, { Fragment, useMemo } from 'react';
import dayjs from '@/lib/dayjs/dayjs';

import { formatAddress } from '../../../../../helpers/text/formatAddress';
import { genderSlugToReadable } from '../../../../_ui/_blocks/Form/Inputs/SelectGender/SelectGender';
import { QuestionTypes } from '../../../questionComponentIndex';
import { GPSurgery } from '../../../../GPForm/types/GPSurgery';

import styles from './PrefilledAnswer.module.css';

export interface WeightAndHeightAnswer {
    weight: number;
    height: number;
}

export interface ImageAnswer {
    original: string;
    thumbnail: string;
}

export type GPAnswer = Omit<GPSurgery, 'email' | 'NHSGPPracticeCode' | 'manualEdit'>;

type GenderAnswer = 'male' | 'female' | 'male-identifies-female' | 'female-identifies-male';

export type AnswerTypes = string | number | Array<string | number> | GenderAnswer | WeightAndHeightAnswer | ImageAnswer | GPAnswer;

export interface PrefilledAnswerProps {
    questionType: QuestionTypes;
    options: Array<string | number>;
    answer?: AnswerTypes;
    moreDetail?: string;
    changeAnswer: () => void;
}

const PrefilledAnswer = ({ questionType, options, answer = undefined, moreDetail = undefined, changeAnswer }: PrefilledAnswerProps) => {
    const formattedAnswer = useMemo(() => {
        if (questionType === 'select' || questionType === 'radio') return <span>{answer as string}</span>;

        if (questionType === 'checkbox') return <span>{answer ? 'Yes' : 'No'}</span>;

        if (questionType === 'multiple-selection') {
            if (!answer) return null;
            return <span>{(answer as Array<unknown>).join(', ')}</span>;
        }

        if (questionType === 'free-text' || questionType === 'number') {
            return <p>{(answer as string) || 'No answer supplied.'}</p>;
        }

        if (questionType === 'gender') {
            return <span>{genderSlugToReadable(answer as GenderAnswer)}</span>;
        }

        if (questionType === 's-gp') {
            if (!answer || answer === null) return <p>No GP details provided</p>;

            return (
                <address className="pre">{formatAddress(answer, ['name', 'address1', 'address2', 'city', 'county', 'postcode'])}</address>
            );
        }

        if (questionType === 's-bmi') {
            if (!answer || answer === null) return 'No';
            return (
                <span>
                    Height: {(answer as WeightAndHeightAnswer).height} cm
                    <br />
                    Weight: {(answer as WeightAndHeightAnswer).weight} kg
                </span>
            );
        }

        if (questionType === 'date') {
            if (!answer) {
                return 'No date supplied.';
            }

            const date = dayjs(answer as string);

            if (!date.isValid()) {
                return 'No date supplied.';
            }

            return <span>{date.format('DD/MM/YYYY')}</span>;
        }

        if (questionType === 'image') {
            if (!answer) return <p>No image was supplied.</p>;
            return (
                <p>
                    Your image <strong className="word-break--break-word">{(answer as ImageAnswer).original}</strong> was securely uploaded
                </p>
            );
        }

        /**
         * Return yes or no to catch anything else.
         */
        return <span>{answer ? 'Yes' : 'No'}</span>;
    }, [answer, options, questionType]);

    const editButton = (
        <button
            className="type-090 type-link-reverse"
            type="button"
            onClick={changeAnswer}
            title="Edit the answer(s) you provided for this question"
        >
            Edit
        </button>
    );

    return (
        <Fragment>
            <div className={styles.prefilledAnswer}>
                {formattedAnswer}
                {editButton}
            </div>
            {moreDetail ? (
                <div className={styles.prefilledAnswer}>
                    <pre className="type-paragraph">{moreDetail}</pre>
                    {editButton}
                </div>
            ) : null}
        </Fragment>
    );
};

export default PrefilledAnswer;
