import React from 'react';

import { formatAddress } from '../../../../helpers/text/formatAddress';
import { formatName } from '../../../../helpers/text/formatName';
import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import Typography from '../../../_ui/_blocks/Typography/Typography';

import Card from '../../../_ui/_blocks/Card/Card';

interface AddressName {
    title: string;
    firstname: string;
    middlename?: string;
    lastname: string;
}

interface DefaultAddress {
    businessName: string;
    address1: string;
    address2?: string;
    city: string;
    county: string;
    postcode: string;
}

interface Props<T> {
    address: T | DefaultAddress;
    name?: AddressName;
    format?: string[];
    buttonText?: string;
    onClick?: React.MouseEventHandler;
    oneLine?: boolean;
}

const PrefilledAddress = <T,>({ name, address, format, buttonText, onClick, oneLine }: Props<T>) => (
    <Card variant="bordered" className="p-100 flex gap-100 items-start" disableBoxShadow>
        <div className="space-y-050 flex-grow">
            {name ? <Typography size="090">{formatName(name)}</Typography> : null}
            <Typography as="address" size="090" className={oneLine ? 'whitespace-normal' : 'whitespace-pre'}>
                {formatAddress(address, format, oneLine ? ', ' : ',\n')}
            </Typography>
        </div>
        {onClick ? (
            <Button
                variant="none"
                size="none"
                onClick={onClick}
                title={`${buttonText} this address`}
                className="type-link !type-090 ml-auto font-main flex-shrink-0"
            >
                {buttonText}
            </Button>
        ) : null}
    </Card>
);

PrefilledAddress.defaultProps = {
    name: null,
    buttonText: 'Edit',
    onClick: undefined,
    format: ['businessName', 'address1', 'address2', 'city', 'county', 'postcode'],
    oneLine: false,
};

export default PrefilledAddress;
