import React, { useState } from 'react';

import { useDidUpdateEffect } from '../../../../../hooks/useDidUpdateEffect';
import { ftInchesToCm, cmToFtInch } from '../../../../../helpers/maths';

import NumberInput from '../../../../_ui/_blocks/Form/Inputs/NumberInput/NumberInput';

interface FtInch {
    ft: string | number;
    inch: string | number;
}

interface Props {
    height: number | string;
    onChange: (height: number) => void;
    isValid: boolean;
}

/**
 * Used to stop adding 0's immediately to the fields, allowing the placeholders to show.
 * @param {number} height - Height of the person.
 */
const formatFeetInches = (height: any) => {
    const ftIn = cmToFtInch(height) as FtInch;

    if (!height && ftIn.ft === 0) ftIn.ft = '';
    if (!height && ftIn.inch === 0) ftIn.inch = '';

    return ftIn;
};

const FtAndIn = ({ height, onChange, isValid }: Props) => {
    const [ftAndIn, setFtAndIn] = useState<FtInch>(formatFeetInches(height));

    useDidUpdateEffect(() => {
        onChange(Math.round(ftInchesToCm(ftAndIn.ft || 0, ftAndIn.inch || 0) as unknown as number));
    }, [ftAndIn, onChange]);

    const handleChange = (key: string, value: number | string) => {
        setFtAndIn((state) => ({ ...state, [key]: value }));
    };

    return (
        <div className="flex flex-grow gap-050">
            <NumberInput
                id="form-field--height-ft"
                label="Feet (ft)"
                name="feet"
                type="number"
                placeholder="ft"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('ft', e.target.value)}
                value={ftAndIn.ft}
                isValid={isValid}
                min={0}
                max={8}
                className="w-[5.25rem]"
            />
            <NumberInput
                id="form-field--height-in"
                label="Inches (in)"
                name="inches"
                type="number"
                placeholder="in"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('inch', e.target.value)}
                value={ftAndIn.inch}
                isValid={isValid}
                min={0}
                max={11}
                className="w-[5.25rem]"
            />
        </div>
    );
};

export default FtAndIn;
