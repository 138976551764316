import React from 'react';
import clx from 'classnames';

import styles from './OptionBlock.module.css';

interface Props extends React.HTMLAttributes<HTMLElement> {
    as?: 'div' | 'label' | 'span' | 'li';
    checked?: boolean;
    isDirty?: boolean;
    isValid?: boolean;
    disabled?: boolean;
    children: React.ReactNode;
    htmlFor?: string;
}

const OptionBlock = ({ as: Tag = 'div', checked, isDirty, isValid, disabled, children, className, ...rest }: Props) => {
    const tagClassNames = clx(styles.block, className, {
        [styles.indicatorChecked]: checked,
        [styles.indicatorInvalid]: isDirty && !isValid,
        [styles.indicatorDisabled]: disabled,
    });

    return (
        <Tag {...rest} className={tagClassNames}>
            <span className={styles.indicator} />
            {children}
        </Tag>
    );
};

export default OptionBlock;
