import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import assetsService from '../../../../data/services/assets.service';
import useDataApi from '../../../../hooks/useDataApi';

import Button from '../../../_ui/_blocks/Buttons/Button/Button';
import Loading from '../../../_ui/_blocks/Loading/Loading';
import Error from '../../../_ui/_blocks/Form/Validation/Error/Error';
import Icon from '../../../_ui/_blocks/Icon/Icon';
import Typography from '../../../_ui/_blocks/Typography/Typography';

import styles from './QTImage.module.css';

export const validation = (val) => !!val;

const MAX_FILE_SIZE = 10485760; // 10MB
const FILES_TO_ACCEPT = {
    'image/*': ['.jpeg', '.jpg', '.gif', '.png'],
};

/**
 * Question type === image
 *
 * This is a controllable component however this has a bit of an edge case.
 *
 * Dragging or selecting an image will automatically upload it to the api, the api will return a
 * url.
 */
const QTImage = ({ value, onChange, isValid, allowRemove, assetType, uploadIsPermanent }) => {
    const [errorMessage, setErrorMessage] = useState('');

    const [{ isLoading }, uploadImage] = useDataApi((postData) => assetsService.createAsset(postData));

    const resetImage = (e) => {
        if (e) e.stopPropagation();
        onChange(undefined, validation(undefined));
    };

    const genericError = 'Something went wrong with your image upload, please try again or contact support';

    const handleDrop = async (acceptedFiles) => {
        if (!acceptedFiles || !acceptedFiles.length) return;

        const postData = new FormData();
        postData.append('file', acceptedFiles[0]);
        postData.append('type', assetType);

        const response = await uploadImage(postData);

        if (!response) {
            setErrorMessage(genericError);
            resetImage();
            return;
        }

        const {
            data: {
                data: { filename: generated },
            },
        } = response;

        const filenames = { generated, original: acceptedFiles[0].name };

        setErrorMessage('');
        onChange(filenames, validation(filenames));
    };

    const handleDropRejected = (files) => {
        if (!files || !files.length) return;

        const error = files[0].errors[0];

        switch (error.code) {
            case 'file-invalid-type':
                setErrorMessage('Your image is not an accepted file type. Please use one of the following: jpeg, jpg, gif, png');
                return;
            case 'file-too-large':
                setErrorMessage('Your image size is larger than 10MB. Please use a smaller image to upload');
                return;
            case 'too-many-files':
                setErrorMessage('Only one image can be uploaded');
                return;
            default:
                setErrorMessage(genericError);
        }
    };

    return (
        <Fragment>
            <div data-testid="image">
                <Dropzone
                    onDrop={handleDrop}
                    onDropRejected={handleDropRejected}
                    disabled={!!value}
                    noClick={!!value}
                    maxFiles={1}
                    maxSize={MAX_FILE_SIZE}
                    accept={FILES_TO_ACCEPT}
                >
                    {({ getRootProps, getInputProps }) => (
                        value ? (
                            <div className={styles.imageQuestion}>
                                <Icon icon="stamp-light" alt="Image upload complete" size="xlarge" />
                                <Typography
                                    as="p" color="quiet" size="090" className={styles.successMessage}>
                                    Your file{' '}
                                    <span className="break-words text-content-foundation">
                                        {value.original}
                                    </span> has been securely uploaded.
                                    {uploadIsPermanent ? '' : ' Any unused images are deleted in our system after 15 days.'}
                                </Typography>
                                {allowRemove ? (
                                    <Button size="small" variant="secondary" onClick={resetImage}>
                                        Remove or change image
                                    </Button>
                                ) : null}
                            </div>
                        ) : (
                            <div
                                className={`${styles.imageQuestion} ${styles.interactive} ${!isValid || errorMessage ? styles.error : ''}`}
                                title="Drag and drop or click to upload an image"
                                {...getRootProps()}
                            >
                                {isLoading
                                    ? <Loading isLoading />
                                    : <Icon icon="profile-light" alt="Image upload" size="xlarge" />}
                                <Typography
                                    as="div"
                                    color="quiet"
                                    size="090"
                                    className="flex gap-050 items-center"
                                >
                                    <Icon icon="lock-light" alt="Padlock" />
                                    Securely upload an image
                                </Typography>
                                <Button title="Add image" size="small" variant="secondary">
                                    Add image
                                </Button>
                                <input {...getInputProps()} />
                            </div>
                        )
                    )}
                </Dropzone>
            </div>
            {errorMessage ? <Error className="mt-050">{errorMessage}</Error> : null}
        </Fragment>
    );
};

QTImage.defaultProps = {
    value: null,
    allowRemove: true,
    assetType: 'PATIENT_IMAGE',
    uploadIsPermanent: false,
};

QTImage.propTypes = {
    value: PropTypes.shape({
        original: PropTypes.string,
        generated: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    allowRemove: PropTypes.bool,
    assetType: PropTypes.oneOf(['PATIENT_IMAGE', 'NOTE_ATTACHMENT', 'NOTE_ATTACHMENT']),
    uploadIsPermanent: PropTypes.bool, // If the image doesn't go into the draft folder in the S3 bucket.
};

export default QTImage;
