import React, { useMemo, useState, useCallback } from 'react';
import dayjs from '@/lib/dayjs/dayjs';

import TextInput from '@/components/_ui/_blocks/Form/Inputs/TextInput/TextInput';

import {
    isDateBetweenYears,
    isDateValid,
    checkDateSupport,
    isDateGreaterThan,
    isDateLessThan,
} from '../../../../helpers/dateFunctions/dateFunctions';
import { useDidUpdateEffect } from '../../../../hooks/useDidUpdateEffect';

const isDateSupported = checkDateSupport();

/**
 * Returns an error message if the date is invalid.
 * @param {string} val - The date to validate.
 * @returns {string}
 */
export const qtDateErrorMessage = (val: any, minDate: any, maxDate?: any) => {
    // If manual input and it doesnt match 2020-12-25 then fail.
    if (!isDateSupported && !val.match(/\d{4}-\d{2}-\d{2}/gm)) {
        return 'Please input the correct date format (YYYY-MM-DD).';
    }

    // If the date is invalid.
    if (isDateValid(val, 'YYYY-MM-DD') === false) {
        return 'Please enter a valid date.';
    }

    let errorMessage = '';

    // Generate the date validation error message.
    switch (isDateBetweenYears(val)) {
        case 'MIN_ERROR':
            errorMessage = 'The date you have set is more than 130 years ago, please review the date you have entered.';
            break;
        case 'MAX_ERROR':
            errorMessage = 'The date you have set is more than 100 years in the future, please review the date you have entered.';
            break;
        case 'SUCCESS':
        default:
            break;
    }

    // If there is a valid minimum date and the date entered is before it.
    if (minDate && isDateGreaterThan(minDate, val) === false) {
        errorMessage = `The date you've specified is less than the minimum date - ${dayjs(minDate, 'YYY-MMM-DDD')
            .local()
            .format('DD/MM/YYYY')}`;
    }

    // If there is a valid minimum date and the date entered is before it.
    if (maxDate && isDateLessThan(maxDate, val) === false) {
        errorMessage = `The date you've specified is greater than the maximum date - ${dayjs(maxDate, 'YYY-MMM-DDD')
            .local()
            .format('DD/MM/YYYY')}`;
    }

    return errorMessage;
};

export const validation = (val: any, minDate: any) => qtDateErrorMessage(val, minDate) === '';

interface Props {
    name: string;
    value: string;
    onChange: (val: string | number, isValid: boolean) => void;
    isValid: boolean;
    min_date?: string;
    max_date?: string;
}

/**
 * Date question type
 * This is a controllable component meaning the value in the question will not be set unless a
 * higher state will get passed down.
 */
const QTDate = ({ name, value = '', onChange, isValid, min_date: minDate = undefined, max_date: maxDate = undefined }: Props) => {
    const cb = useCallback(onChange, []);
    const [inputValue, setInputValue] = useState(
        isDateValid(value, 'YYYY-MM-DD HH:mm:ss') ? dayjs(value, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DD') : ''
    );
    const [isDirty, setIsDirty] = useState(isDateValid(value, 'YYYY-MM-DD HH:mm:ss'));

    // Gets any error messages to display under the question.
    const errorMessage = useMemo(() => qtDateErrorMessage(inputValue, minDate, maxDate), [inputValue, minDate, maxDate]);

    useDidUpdateEffect(() => {
        // Set is dirty to true.
        if (isDirty === false) {
            setIsDirty(true);
        }

        // Set the default value for the date as 0;
        let date = 0;
        const dateValid = errorMessage === '';

        // If the date is valid, format the date to the long version of the API.
        if (dateValid) {
            // @ts-expect-error
            date = dayjs(inputValue, 'YYYY-MM-DD').local().format('YYYY-MM-DD HH:mm:ss');
        }

        cb(date, dateValid);
    }, [inputValue, isDirty, cb, errorMessage]);

    return (
        <div data-testid="date" className="space-y-050">
            <TextInput
                id={name}
                name={name}
                className="w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
                type="date"
                value={inputValue}
                isValid={isValid}
                min={minDate}
                max={maxDate}
                placeholder={isDateSupported ? 'DD-MM-YYYY' : 'YYYY-MM-DD'}
                pattern={isDateSupported ? 'd*' : '^d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$'}
            />
            {errorMessage && isDirty
                ? <p className="type-090 leading-100 text-status-error-default">{errorMessage}</p>
                : null}
        </div>
    );
};

export default QTDate;
