import React, { useState } from 'react';

import { useDidUpdateEffect } from '@/hooks/useDidUpdateEffect';
import { gramsToStonePounds, stPoundToGrams } from '@/helpers/maths';

import NumberInput from '../../../../_ui/_blocks/Form/Inputs/NumberInput/NumberInput';

interface StAndLb {
    st: string | number;
    lb: string | number;
}

interface Props {
    weight: number | string;
    onChange: (weight: number) => void;
    isValid: boolean;
}

/**
 * Used to stop adding 0's immediately to the fields, allowing the placeholders to show.
 * @param {number} weight - Weight of the person.
 */
const formatStonePounds = (weight: any) => {
    const stLb = gramsToStonePounds(weight) as StAndLb;

    if (!weight && stLb.st === 0) stLb.st = '';
    if (!weight && stLb.lb === 0) stLb.lb = '';

    return stLb;
};

const StAndLb = ({ weight, onChange, isValid }: Props) => {
    const [stAndLb, setStAndLb] = useState<StAndLb>(formatStonePounds(weight));

    useDidUpdateEffect(() => {
        onChange(stPoundToGrams(stAndLb.st || 0, stAndLb.lb || 0));
    }, [stAndLb, onChange]);

    const handleChange = (key: string, value: number | string) => {
        setStAndLb((state) => ({ ...state, [key]: value }));
    };

    return (
        <div className="flex flex-grow gap-050">
            <NumberInput
                id="form-field--weight-st"
                label="Stone (st)"
                name="stone"
                type="number"
                placeholder="st"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('st', e.target.value)}
                value={stAndLb.st}
                isValid={isValid}
                min={0}
                max={100}
                className="w-[5.25rem]"
            />
            <NumberInput
                id="form-field--weight-lb"
                label="Pounds (lb)"
                name="pounds"
                type="number"
                placeholder="lb"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('lb', e.target.value)}
                value={stAndLb.lb ? Math.floor(stAndLb.lb as unknown as number) : ''}
                isValid={isValid}
                min={0}
                max={13}
                className="w-[5.25rem]"
            />
        </div>
    );
};

export default StAndLb;
