/**
 * Accept a group of name fields and convert them into a formatted name string.
 * @param {object} nameObj - The string values to be joined.
 * @param {array[string]} fieldsToLoop - Array of fields to loop through in order of the array passed in.
 */

export const formatName = (nameObj, fieldsToLoop = ['title', 'firstname', 'middlename', 'lastname']) => {
    if (!nameObj) return undefined;

    const nameArr = [];

    fieldsToLoop.forEach((k) => {
        if (nameObj[k] && nameObj[k] !== '') nameArr.push(nameObj[k]);
    });

    const addressStr = nameArr.join(' ');

    return addressStr;
};
