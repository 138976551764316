import React from 'react';
import clx from 'classnames';

import { BorderRadiusMap } from '../styles/types';

import Label from '../Label/Label';

import styles from './TextInput.module.css';
import inputStyles from '../styles/Inputs.module.css';

type LabelProps = React.ComponentProps<typeof Label>;

interface Props extends Omit<React.AllHTMLAttributes<HTMLInputElement>, 'size'>, LabelProps {
    id: string;
    className?: string;
    wrapperTag?: 'div';
    isValid?: boolean;
    isDirty?: boolean;
    type?: 'text' | 'password' | 'email' | 'hidden' | 'tel' | 'date';
    borderRadius?: keyof typeof BorderRadiusMap;
    autofill?: string;
    testId?: string;
    dataLpignore?: boolean;
    size?: 'small' | 'medium';
}

const TextInput: React.FC<Props> = ({
    id,
    label,
    description,
    className,
    wrapperTag,
    isDirty,
    isValid,
    borderRadius = 'default',
    type = 'text',
    optional,
    dataLpignore,
    size = 'medium',
    testId,
    ...rest
}) => {
    let inputOnlyClassName = clx(
        inputStyles.field,
        BorderRadiusMap[borderRadius],
        {
            [inputStyles.fieldValid]: isDirty && isValid,
            [inputStyles.fieldInvalid]: isDirty && !isValid,
        },
        inputStyles[size],
        type === 'date' ? styles.date : '',
    );

    const Wrapper = wrapperTag || 'div';
    const wrapperClassNames = clx(className, inputStyles.labelWrap);

    if (!label) {
        inputOnlyClassName = clx(inputOnlyClassName, className);
        return <input {...rest} data-lpignore={dataLpignore} id={id} className={inputOnlyClassName} type={type} />;
    }

    return (
        <Wrapper className={wrapperClassNames}>
            <Label id={id} label={label} description={description} optional={optional} />
            <input {...rest} data-lpignore={dataLpignore} id={id} className={inputOnlyClassName} type={type} data-testid={testId} />
        </Wrapper>
    );
};

export default TextInput;
