import React from 'react';

import Select from '@/components/_ui/_blocks/Form/Inputs/Select/Select';

export const validation = (val: string) => !!val;

interface Props {
    name: string;
    value: string | number;
    onChange: (val: string | number, valid: boolean) => void;
    options: Array<string | number>;
    isValid: boolean;
    isDirty: boolean;
}
/**
 * Question type === select
 */
const QTSelect = ({ name, value, onChange, options = ['No options available'], isValid, isDirty }: Props) => {
    const handleOnChange = (val: string) => onChange(val, validation(val));

    if (!options || !options.length) return <p>No options provided, please contact support.</p>;

    return (
        <div data-testid="select">
            <Select
                id={name}
                name={name}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleOnChange(e.target.value)}
                isValid={isValid}
                isDirty={isDirty}
                placeholder="Please select an option"
            >
                {options.map((option) => (
                    <Select.Option key={option} value={option}>
                        {option}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default QTSelect;
