import React from 'react';
import clx from 'classnames';


import styles from './CheckboxIcon.module.css';
import Icon from '../../../../Icon/Icon';

interface Props {
    checked?: boolean;
    disabled?: boolean;
    className?: string;
}

/**
 * Renders a checkbox icon that has different visual states depending on it's checked and disabled states.
 * HeadlessUI doesn't provide a specific checkbox style to work with. However, it has been suggested to use
 * its Switch.Group component as an alternative.
 * @see https://headlessui.com/react/switch
 * @see https://github.com/tailwindlabs/headlessui/discussions/367#discussioncomment-6714131
 */
const CheckboxIcon = ({ checked, disabled, className }: Props) => {
    const classNames = clx(
        styles.checkbox,
        {
            [styles.animated]: checked,
            [styles.instant]: !checked,
            [styles.checked]: checked,
            [styles.disabled]: disabled,
        },
        className
    );

    return (
        <span className={classNames} data-testid="checkbox">
            <Icon icon="active-checkmark-dark" alt="checkmark" size="xxsmall" className={`transition-opacity duration-fast ${checked ? 'opacity-100' : 'opacity-0'}`} />
        </span>
    );
};

export default CheckboxIcon;
