import React from 'react';

import TextInput from '../_ui/_blocks/Form/Inputs/TextInput/TextInput';
import JoiFormInputWrap from '../_ui/_blocks/Form/JoiForm/JoiFormInputWrap/JoiFormInputWrap';
import { GPSurgery } from './types/GPSurgery';

interface Props {
    address: Partial<GPSurgery>;
    isDirty: boolean;
    onChange: (name: keyof GPSurgery, value: unknown) => void;
}

const GPFormFields = ({ address, isDirty, onChange }: Props): JSX.Element => (
    <div className="space-y-150 pt-200">
        <JoiFormInputWrap validateOnChange name="address.name">
            <TextInput
                label="Surgery name"
                id="form-field--name"
                name="name"
                value={address.name || undefined}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('name', e.target.value)}
                isValid={isDirty ? !!address.name : true}
                className="w-full"
                maxLength={300}
            />
        </JoiFormInputWrap>
        <TextInput
            label="Surgery address 1"
            optional
            id="form-field--address-1"
            name="address1"
            value={address.address1 || undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('address1', e.target.value)}
            maxLength={300}
            className="w-full"
        />
        <TextInput
            id="form-field--address-2"
            label="Surgery address 2"
            optional
            name="address2"
            value={address.address2 || undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('address2', e.target.value)}
            maxLength={300}
            className="w-full"
        />
        <TextInput
            label="City"
            optional
            id="form-field--city"
            name="city"
            value={address.city || undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('city', e.target.value)}
            maxLength={300}
            className="w-full"
        />
        <TextInput
            label="County"
            optional
            id="form-field--county"
            name="county"
            value={address.county || undefined}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('county', e.target.value)}
            maxLength={300}
            className="w-full"
        />
        <JoiFormInputWrap validateOnChange name="address.postcode">
            <TextInput
                label="Postcode"
                id="form-field--gp-postcode"
                name="postcode"
                value={address.postcode || undefined}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('postcode', e.target.value)}
                isValid={isDirty ? !!address.postcode : true}
                maxLength={300}
            />
        </JoiFormInputWrap>
    </div>
);

export default GPFormFields;
