import React from 'react';
import clx from 'classnames';

import { BorderRadiusMap } from '../styles/types';

import Label from '../Label/Label';
import Icon from '../../../Icon/Icon';

import styles from '../styles/Inputs.module.css';

type LabelProps = React.ComponentProps<typeof Label>;

interface Props extends React.AllHTMLAttributes<HTMLSelectElement>, LabelProps {
    id: string;
    className?: string;
    wrapperTag?: 'div';
    isValid?: boolean;
    isDirty?: boolean;
    type?: 'text' | 'password' | 'email' | 'hidden' | 'tel';
    borderRadius?: keyof typeof BorderRadiusMap;
    autofill?: string;
    dataLpignore?: boolean;
    children: React.ReactNode;
}

type OptionProps = React.AllHTMLAttributes<HTMLOptionElement>;

const Option = ({ children, ...rest }: OptionProps) => <option {...rest}>{children}</option>;

const Select = ({
    id,
    label,
    description,
    optional,
    className,
    wrapperTag,
    isDirty,
    isValid,
    borderRadius = 'default',
    children,
    placeholder,
    ...rest
}: Props) => {
    let inputOnlyClassName = clx(styles.select, styles.field, BorderRadiusMap[borderRadius], {
        [styles.fieldValid]: isDirty && isValid,
        [styles.fieldInvalid]: isDirty && !isValid,
    });

    if (!label) {
        inputOnlyClassName = clx(inputOnlyClassName, className);
        return (
            <div className="relative">
                <select {...rest} id={id} className={inputOnlyClassName}>
                    {placeholder ? (
                        <option value="" disabled>
                            {placeholder}
                        </option>
                    ) : null}
                    {children}
                </select>
                <Icon size="xsmall" icon="chevron-down-light" alt="chevron light icon" />
            </div>
        );
    }

    const Wrapper = wrapperTag || 'div';
    const wrapperClassNames = clx(className, styles.labelWrap);

    return (
        <Wrapper className={wrapperClassNames}>
            <Label id={id} label={label} description={description} optional={optional} />
            <div className="relative">
                <select {...rest} id={id} className={inputOnlyClassName}>
                    {placeholder ? (
                        <option value="" disabled>
                            {placeholder}
                        </option>
                    ) : null}
                    {children}
                </select>
                <Icon size="xsmall" icon="chevron-down-light" alt="chevron light icon" />
            </div>
        </Wrapper>
    );
};

Select.Option = Option;

export default Select;
