import React from 'react';
import Button from '../../_ui/_blocks/Buttons/Button/Button';
import Typography from '../../_ui/_blocks/Typography/Typography';

interface Props {
    onClick: () => void;
}

const EnterManuallyButton = ({ onClick }: Props) => (
    <Button
        type="button"
        className="p-0 type-080 type-link font-main"
        onClick={onClick}
        title="Enter address manually"
        variant="none"
    >
        <Typography typeset="link">Enter manually</Typography>
    </Button>
);

export default EnterManuallyButton;
