import React, { Fragment } from 'react';
import { RadioGroup } from '@headlessui/react';
import clx from 'classnames';

import RadioIcon from '../RadioIcon/RadioIcon';
import OptionBlock from '../../OptionBlock/OptionBlock';

import styles from './RadioOption.module.css';

interface Props extends Omit<React.ComponentProps<typeof RadioGroup.Option>, 'as'> {
    disabled?: boolean;
    children: string;
    renderInOptionBlock?: boolean;
    isValid?: boolean;
    isDirty?: boolean;
}

/**
 * Radio group option component using headlessUIs radio group  option component.
 * @see https://headlessui.com/react/radio-group
 */
const RadioOption = ({ children, className, renderInOptionBlock, isValid, isDirty, ...rest }: Props) => {
    const remainingProps = { ...rest };
    const { disabled } = remainingProps;

    const radioClassName = clx(styles.option, className, {
        [styles.optionDisabled]: disabled,
    });

    const Wrapper = renderInOptionBlock ? OptionBlock : Fragment;

    return (
        <RadioGroup.Option {...remainingProps} className={`${radioClassName} type-heading type-090`}>
            {({ checked }) => (
                // ⚠️ important ⚠️  If the wrapper is a fragment the classNames will not get applied.
                <Wrapper checked={checked} isValid={isValid} isDirty={isDirty} disabled={disabled} className="!flex w-full gap-100">
                    <RadioIcon className="flex-shrink-0" checked={checked} disabled={disabled} />
                    {children}
                </Wrapper>
            )}
        </RadioGroup.Option>
    );
};

export default RadioOption;
