import React from 'react';

import Select from '../Select/Select';

import styles from './SelectGender.module.css';

export const genders = {
    male: 'Male',
    female: 'Female',
    'male-identifies-female': 'Trans Female (Male at birth)',
    'female-identifies-male': 'Trans Male (Female at birth)',
};

const aliases = {
    'male-identifies-female': 'male',
    'female-identifies-male': 'female',
};

type aliasKeys = keyof typeof aliases;

export const genderSlugToReadable = (slug: keyof typeof genders) => genders[slug];
export const convertGenderAlias = (alias: aliasKeys) => aliases[alias] || alias;

type SelectProps = Pick<React.ComponentProps<typeof Select>, 'label' | 'description'>;

export interface Props extends SelectProps {
    value?: keyof typeof genders | '';
    gender?: keyof typeof genders | '';
    defaultValue?: keyof typeof genders;
    /** If the field value is valid, if not add class. */
    isValid?: boolean;
    required?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    testId?: string;
}

const SelectGender = ({ gender, value, defaultValue, onChange, isValid, testId, required, ...rest }: Props) => (
    <Select
        id="form-field--gender"
        name="gender"
        placeholder="Select your sex..."
        onChange={onChange}
        isValid={isValid}
        value={gender || value || ''}
        className={styles.select}
        defaultValue={defaultValue}
        data-testid={testId}
        required={required || false}
        {...rest}
    >
        {Object.keys(genders).map((g) => (
            <Select.Option key={g} value={g}>
                {genders[g as keyof typeof genders]}
            </Select.Option>
        ))}
    </Select>
);

SelectGender.defaultProps = {
    gender: undefined,
    defaultValue: undefined,
    isValid: true,
    onChange: undefined,
    testId: undefined,
};

export default SelectGender;
