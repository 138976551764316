import React from 'react';

import Radio from '../../../../_ui/_blocks/Form/Inputs/Radio/Radio';

interface Props {
    name: string;
    value?: string | number;
    onChange: (val: 1 | 0) => void;
    isValid: boolean;
    isDirty: boolean;
}

const YesNo = ({ name, value = undefined, onChange, isValid, isDirty }: Props) => (
    <Radio name={name} value={value} onChange={(val: 1 | 0) => onChange(val)} className="grid grid-cols-2 gap-100">
        <Radio.Option renderInOptionBlock isValid={isValid} isDirty={isDirty} value={1}>
            Yes
        </Radio.Option>
        <Radio.Option renderInOptionBlock isValid={isValid} isDirty={isDirty} value={0}>
            No
        </Radio.Option>
    </Radio>
);

export default YesNo;
