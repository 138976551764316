import React from 'react';
import clx from 'classnames';

import styles from './RadioIcon.module.css';

interface Props {
    checked?: boolean;
    disabled?: boolean;
    className?: string;
}

/**
 * Renders a radio icon that has different visual states depending on it's checked and disabled states.
 * It should be used in conjunction with @see https://headlessui.com/react/radio-group.
 */
const RadioIcon = ({ checked, disabled, className }: Props) => {
    const classNames = clx(
        styles.radio,
        {
            [styles.checked]: checked,
            [styles.disabled]: disabled,
        },
        className
    );

    return (
        <span className={classNames} data-testid="radio">
            <span />
        </span>
    );
};

export default RadioIcon;
