import { MultipleSelectChoice } from '../types/MultipleSelectChoice';

/**
 * If a deselectTrigger is set and someone selects it, remove all other choices and select the deselect trigger only
 * If its already selected and someone selects something else, deselect it and only show the new option
 */
export const deselectTriggerfilter = (
    deselectTrigger: MultipleSelectChoice[] | undefined,
    // eslint-disable-next-line default-param-last
    choices: MultipleSelectChoice[] = [], // TODO: This will be refactored when the consultation is redone.
    selectedChoice: MultipleSelectChoice
): MultipleSelectChoice[] => {
    const triggers = deselectTrigger || [];

    const includesDeselectTrigger = triggers.find((dt) => {
        if (choices.includes(dt)) {
            return true;
        }
        return false;
    });

    if (includesDeselectTrigger) return [selectedChoice];

    return choices;
};
