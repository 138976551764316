import React, { useMemo } from 'react';

import Radio from '../../../_ui/_blocks/Form/Inputs/Radio/Radio';

export const validation = (val: boolean): boolean => !!val;

interface Props {
    name: string;
    value?: string;
    onChange: (value: any, isValid: boolean) => void;
    options: string[];
    isValid: boolean;
    isDirty: boolean;
}

/**
 * Question type === radio
 *
 * This is a controllable component meaning the radios will not be checked unless a higher state
 * will get passed down with the value that matches the an item in the options.
 */
const QTRadio = ({ name, value, onChange, options, isValid, isDirty }: Props): JSX.Element => {
    const handleOnChange = (value: string) => onChange(value, validation(true));

    // Converts every option to a string.
    // We allow in the CP &#x2c; to be input in the option so that we can display a comma in the options.
    const formattedOptions = useMemo(() => {
        if (!options || !options.length) {
            return [];
        }

        return options.map((option) => option.toString().replace('&#x2c;', ','));
    }, [options]);

    // If there are no options, display a warning to the user.
    if (!formattedOptions.length) return <p>No options provided, please contact support.</p>;

    return (
        <Radio name={name} value={value} onChange={handleOnChange} className="grid gap-100">
            {formattedOptions.map((option) => (
                <Radio.Option
                    key={`${name}_${option}`}
                    id={`${name}_${option}`}
                    renderInOptionBlock
                    isValid={isValid}
                    isDirty={isDirty}
                    value={option}
                >
                    {option}
                </Radio.Option>
            ))}
        </Radio>
    );
};

QTRadio.defaultProps = {
    value: undefined,
};

export default QTRadio;
